<template>
  <v-dialog persistent v-model="dialog_bulk_vendors" max-width="80%">
    <v-card class="card-shadow">
      <div class="card-header-padding"></div>
      <v-card-text class="card-padding">
        <v-container class="px-0">
          <div class="card-header-padding">
            <span class="font-weight-bold text-h5 text-typo mb-0"
              >Missing Vendors</span
            >
          </div>
          <v-divider></v-divider>
          <v-form ref="frm">
            <v-card v-for="(data, i) in input_data" :key="i" class="mt-5">
              <div class="card-header-padding">
                <span class="font-weight-bold text-h5 text-typo mb-0"
                  >Vendor {{ i + 1 }}</span
                >
              </div>

              <v-row>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Name</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].name"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Name is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Name"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Address</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].address"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Address is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Address"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Phone</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].phone"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Phone is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Phone"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Email</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].email"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Email is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Email"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Pan No</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].pan_no"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Pan No is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Pan No"
                    persistent-hint
                  ></v-text-field>
                </v-col> </v-row
              ><v-row>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >TB reg No</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].tb_reg_no"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'TB reg No is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter TB reg No"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Cin</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].cin"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Cin is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Cin"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1">
                    FSSAI License No</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].fssai_license_no"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'FSSAI License No is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter FSSAI License No"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >GST No</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].gst_no"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Gst No is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Gst No"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Payment Day Limit</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].payment_day_limit"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Payment Day Limit"
                    :rules="[
                      (v) => !!v || 'Payment day limit is required',
                      (v) => v >= 0 || 'Min should 0 or More',
                    ]"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Vendor Code</label
                  >

                  <v-text-field
                    v-model="editedItem.vendors[i].vendor_code"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-2
                    "
                    :rules="[(v) => !!v || 'Vendor Code is required']"
                    dense
                    flat
                    filled
                    solo
                    height="48"
                    placeholder="Enter Vendor Code"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
            </v-card>
          </v-form> </v-container
      ></v-card-text>
      <v-card-actions class="card-padding d-flex justify-end">
        <v-btn
          @click="close"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-capitalize
            btn-ls btn-secondary
            bg-gradient-light
            py-3
            px-6
          "
          >Cancel</v-btn
        >

        <v-btn
          @click="save"
          elevation="0"
          :ripple="false"
          height="43"
          dark
          class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../api";
export default {
  props: {
    input_data: Array,
    dialog_bulk_vendors: Boolean,
  },
  data() {
    return {
      editedItem: {
        vendors: [
          {
            name: "",
            address: "",
            phone: "",
            email: "",
            pan_no: "",
            tb_reg_no: "",
            cin: "",
            fssai_license_no: "",
            gst_no: "",
            payment_day_limit: "",
            vendor_code: "",
          },
        ],
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        this.input_data &&
        this.input_data?.length > 0 &&
        this.input_data != undefined &&
        this.input_data != null
      ) {
        this.editedItem.vendors = this.input_data;
        this.dialog_bulk_vendors = true;
      }
    },
    validate() {
      return this.$refs.frm.validate();
    },
    async save() {
      if (this.validate()) {
        console.log("this.editedItem", this.editedItem);
        try {
          let type = "Saved";
          await api.bulk(this.editedItem);
          this.$emit("save_dialog_bulk_vendors");
          this.close();
          // this.showSuccessAlert(`Vendor ${type}.`);
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      }
    },
    close() {
      this.$nextTick(() => {
        // this.$router.push({ name: "ContractNote" });
        this.$emit("close_dialog_bulk_vendors");
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
  },
};
</script>

<style></style>
